<template>
  <div class="paging-box-el">
<!--    <div class="paging-info">-->
<!--      <div v-if="showInfo">-->
<!--        第{{ currentPage }}页 {{ pageCurTotal }}{{ desc }} ，共{{-->
<!--          Math.ceil(total / pageSize)-->
<!--        }}页-->
<!--      </div>-->
<!--    </div>-->
    <div class="paging-wrap">
      <el-pagination
          background
          layout=" prev, pager, next"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="total"
          @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 100,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 12,
    },
    showInfo: {
      type: Boolean,
      default: true,
    },
    desc: {
      type: String,
      default: '',
    },
    pageCurTotal: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    currentChange(val) {
      this.$emit('currentChange', val);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.paging-box-el {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //height: 32px;

  .paging-info {
    color: #999;
    font-size: 14px;
    letter-spacing: 1px;
  }
}
</style>

<style lang="scss">
.paging-box-el {
  .el-pagination {
    padding: 0;
  }
  .el-pagination.is-background {
    .el-pager li,
    .btn-prev,
    .btn-next {
      background-color: rgba(0,0,0,0);
      //border-radius: 5px;
    }
    .el-pager {
      .el-icon-more {
        background-color: rgba(0, 0, 0, 0);
      }
    }
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #DC2525;
    //border: 1px solid #fff;
    border-radius: 15px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #BA0704;
  }
  .el-pagination.is-background .el-pager .number.active:hover {
    color: #fff;
  }
  .el-pagination.is-background .el-pager li {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    padding: 0;
    line-height: 32px;
    font-size: 18px;
    color: #000;
  }
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .btn-next {
    width: 32px;
    height: 32px;
    //border: 1px solid #dee2e4;
    &:hover {
      .el-icon-arrow-right::before {
        color: #BA0704;
      }
      .el-icon-arrow-left::before {
        color: #BA0704;
      }
    }
  }
}
</style>
