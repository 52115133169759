<template>
  <div class="main-content">
    <div class="main-title">作品打包列表</div>
    <div class="main-btn" @click="startBak">
      <span class="btn-add">打包下载全部资料</span>
      <img class="icon" src="@/assets/works_downloads_icon.png">
    </div>
    <table>
      <tr>
        <th width="100">ID</th>
        <th class="td-left" width="200">打包名称</th>
        <th class="td-left">打包作品</th>
        <th class="td-left" width="200">打包开始时间</th>
        <th class="td-left" width="200">打包完成时间</th>
        <th width="100">状态</th>
        <th width="100">操作</th>
      </tr>
      <template v-for="(item) in list">
        <tr class="row" :key="item.id" @click="rowSelect(item.id)">
          <td class="td-center">{{item.id}}</td>
          <td>{{item.title}}</td>
          <td>{{item.works}}</td>
          <td>{{item.created_at}}</td>
          <td>{{item.updated_at}}</td>
          <td class="td-center" :class="stateStyleKV[item.state]">{{stateNameKV[item.state]}}</td>
          <td class="td-center">
            <div v-if="item.state == 3" class="op-row download" @click="downloadBak(item.download_url)">下载</div>
          </td>
        </tr>
      </template>
    </table>
    <div class="paging-box">
      <paging :current-page="page" :total="total" :page-size="9" @currentChange="pageChange"/>
    </div>
  </div>
</template>

<script>
import {getBakWorksList,startBakWorks} from "@/request/api";
import paging from "@/components/paging";
import {Warning} from "@/plugins/warning";

export default {
  name: "sys_bak",
  components:{
    paging
  },
  data() {
    return {
      list:[],
      page: 0,
      total: 0,
      totalPage: 0,
      selectRow: 0,
      stateNameKV:{
        1:"准备中",
        2:"打包中",
        3:"打包完毕",
        9:"打包失败"
      },
      stateStyleKV:{
        1:"black",
        2:"gold",
        3:"green",
        9:"red"
      }
    }
  },
  computed: {

  },
  created() {
    this.getList();
  },
  mounted() {

  },
  methods: {
    getList(e){
      const curPage = e || this.page
      const requestData = {
        page: curPage,
      };
      getBakWorksList(requestData).then((res)=>{
        this.list = res.data.list;
        this.total = res.data.paging.recordCount
        this.totalPage = res.data.paging.pageCount
        this.page = curPage
      })
    },
    pageChange(e){
      this.getList(e)
    },
    rowSelect(sel){
      if(sel == this.selectRow){
        this.selectRow = 0;
      }else{
        this.selectRow = sel;
      }
    },
    downloadBak(sel){
      window.open(sel,"_blank");
    },
    startBak(){
      this.$confirm("确定是否开始打包?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const requestData = {};
        startBakWorks(requestData).then(()=>{
          Warning.success('打包已经开始，请等待打包完成后下载打包文件');
          this.getList(0);
        })
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .main-btn{
    width: 200px;
    height: 50px;
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-right: 30px;
    margin-top: 30px;
    position: relative;
    cursor: pointer;
    .btn-add{
      display: block;
      line-height: 50px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #DC2525;
      vertical-align:middle;
      float: left;
    }
    .icon{
      width: 30px;
      height: 30px;
      vertical-align:middle;
      float: left;
      position:absolute;
      top:10px
    }
  }
  & > table{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    & > tr{
      & > th{
        font-size: 18px;
        font-family: Jost-Medium, Jost;
        font-weight: 500;
        color: #000;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .td-left{
      text-align: left;
    }
    .td-center{
      text-align: center;
    }
    .row{
      background: #f8f6f0;
      border-radius: 15px;
      box-shadow: 0 0 0 5px #f8f6f0;
      height: 50px;
      font-size: 17px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #2E2E2E;
      position: relative;
      td{
        vertical-align:middle;
        padding-left: 15px;
        padding-right: 15px;
      }
      .download{
        cursor: pointer;
      }
      .black{
        color: #000;
      }
      .red{
        color: red;
      }
      .green{
        color: green;
      }
    }
  }

  .paging-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 0 130px;
  }
}
</style>
